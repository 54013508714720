(function () {
  var ua = navigator.userAgent;
  if (
    ua.indexOf("iPhone") > 0 ||
    ua.indexOf("iPod") > 0 ||
    (ua.indexOf("Android") > 0 && ua.indexOf("Mobile") > 0) ||
    ua.indexOf("Windows Phone") > 0 ||
    ua.indexOf("iPad") > 0
  ) {
    var txWindow = document.querySelector(".tx-warning--pc");
    txWindow.setAttribute("class", "tx-warning is-notwindow");
    var btn = document.querySelector(".box-btn");
    btn.setAttribute("class", "box-btn inactive");
    var terms = document.querySelector(".link-terms");
    terms.setAttribute("class", "link-terms inactive");
  } else if (ua.toLowerCase().indexOf("win") === -1) {
    var txWindow = document.querySelector(".tx-warning--win");
    txWindow.setAttribute("class", "tx-warning is-notwindow");
    var btn = document.querySelector(".box-btn");
    btn.setAttribute("class", "box-btn inactive");
    var terms = document.querySelector(".link-terms");
    terms.setAttribute("class", "link-terms inactive");
  }

  var iframe;
  var timerBtnDisabled;
  var timerInstallCheck;
  var boxBtn = document.querySelector(".box-btn");
  var btnStart = document.querySelector(".btn-start");
  var urlScheme;

  btnStart.addEventListener("click", startPlayer);

  window.onload = addIframe;

  function addIframe() {
    iframe = document.createElement("iframe");
    iframe.style.display = "none";
    document.body.appendChild(iframe);
  }

  function startPlayer(event) {
    event.preventDefault();
    clearTimeout(timerInstallCheck);
    timerInstallCheck = setTimeout(function () {
      window.removeEventListener("blur", onBlur);
      showTx();
    }, 5000);

    window.focus();
    window.addEventListener("blur", onBlur);

    urlScheme = btnStart.getAttribute("href");
    iframe.src = urlScheme;

    boxBtn.setAttribute("class", "box-btn is-btn-disabled");
    clearTimeout(timerBtnDisabled);
    timerBtnDisabled = setTimeout(function () {
      boxBtn.setAttribute("class", "box-btn");
    }, 5000);
  }

  function onBlur() {
    clearTimeout(timerInstallCheck);
  }

  function showTx() {
    var txInstall = document.querySelector(".tx-install");
    txInstall.setAttribute("class", "tx-install is-notinstalled");
    var linkPlayer = document.querySelector(".link-player");
    linkPlayer.setAttribute("class", "link-player is-notinstalled");
  }
})();
