const ageVerification = document.getElementById("common-age-verification");
const agreeButton = document.getElementById("common-age-verification-agree");
const AGE_COOKIE = "_age_check";

function closeAgeVerificationView() {
  ageVerification.parentNode.removeChild(ageVerification);
  document.scrollingElement.scrollTop = 0;
  document.cookie = `${AGE_COOKIE}=1`;
}

function isReferrerFromDmmGames() {
  const referrer = document.referrer;
  const regex = /^https?:\/\/([a-zA-Z0-9-]+\.)?games\.dmm\.co\.jp(\/|$)/;

  return regex.test(referrer);
}

function hasAgeCheckOkParameter() {
  const params = new URLSearchParams(window.location.search);
  return params.get("ageCheck") === "ok";
}

function checkAgeVerificationNecessary(element) {
  if (!element) return;

  try {
    if (isReferrerFromDmmGames() || hasAgeCheckOkParameter()) {
      closeAgeVerificationView();
      return;
    }
  } catch (e) {
    console.error(e);
  }

  element.addEventListener("click", closeAgeVerificationView);
}

checkAgeVerificationNecessary(agreeButton);
