import $ from 'jquery'
import Swiper, {
  Navigation,
  Pagination,
  Thumbs,
  Autoplay,
} from 'swiper';

const movieTitleByID = {
  1: '【千年戦争アイギス】<br class="sp">9周年記念アニバーサリーPV',
  2: '【千年戦争アイギス】<br class="sp">8.5周年記念ハーフアニバ―サリーPV',
  3: '【千年戦争アイギス】<br class="sp">スペシャルドットムービー',
  4: '【千年戦争アイギス】<br class="sp">8周年記念動画公開！',
  5: '【千年戦争アイギス】<br class="sp">7周年記念動画公開！',
  6: '【千年戦争アイギス】<br class="sp">6周年記念動画公開！',
  7: '本格タワーディフェンスRPG<br class="sp">「千年戦争アイギスA」PV',
  8: '【千年戦争アイギス】<br class="sp">アイドルイベントMV',
  9: '【千年戦争アイギス】9.5周年記念PV',
  10: '【千年戦争アイギス】<br class="sp">2023年水着ユニット特別PV',
  11: '【千年戦争アイギス】<br class="sp">10周年記念アニバ―サリーPV',
  12: '【千年戦争アイギス】<br class="sp">2024年も水着ユニットが登場！！',
  13: '【千年戦争アイギス】<br class="sp">11周年記念アニバ―サリーイメージPV',
}

var sliderThumbnail = new Swiper('.movie-slider-thumbnail', {
  slidesPerView: 5,
  freeMode: true,
  watchSlidesProgress: true,
});

new Swiper('.movie-slider', {
  modules: [Navigation, Pagination, Thumbs],
  slidesPerView: 1,
  initialSlide: 0,
  spaceBetween: 0,
  speed: 2000,
  centeredSlides: true,
  loop: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  thumbs: {
    swiper: sliderThumbnail
  },
  breakpoints: {
    768: {
      slidesPerView: 1.7,
      spaceBetween: -40,
      speed: 1000,
    }
  },
  on: {
    // スライド変更時にタイトルの表示とポインターの表示を変更する
    slideChange: function () {
      const movieID = this.slides[this.activeIndex].dataset.title
      $("#movie .thumbnail .point").remove()
      $("#movie .gold-title .text").html(movieTitleByID[movieID])
      $("#movie .thumbnail .thumbnail-" + movieID).append('<img class="point" src="/assets/images/movie_point.png">')
    }
  }
});


new Swiper('.about-slider', {
  modules: [Autoplay],
  slidesPerView: 1,
  initialSlide: 0,
  speed: 1000,
  centeredSlides: true,
  loop: true,
  autoplay: {
    delay: 5000,
  },
});
